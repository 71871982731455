import { render, staticRenderFns } from "./order.vue?vue&type=template&id=213aa88e&scoped=true"
import script from "./order.vue?vue&type=script&lang=js"
export * from "./order.vue?vue&type=script&lang=js"
import style0 from "./order.vue?vue&type=style&index=0&id=213aa88e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "213aa88e",
  null
  
)

export default component.exports