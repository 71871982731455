import { render, staticRenderFns } from "./_channel_type.vue?vue&type=template&id=0b5ff530&scoped=true"
import script from "./_channel_type.vue?vue&type=script&lang=js"
export * from "./_channel_type.vue?vue&type=script&lang=js"
import style0 from "./_channel_type.vue?vue&type=style&index=0&id=0b5ff530&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b5ff530",
  null
  
)

export default component.exports